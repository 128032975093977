export default {
  data() {
    return {
      timeCurrent: this.$moment(this.$moment().format('LLL'), 'YYYY-M-D H:mm:ss').unix() * 1000
    }
  },

  methods: {
    isExpiredTime(timeUnix) {
      if (timeUnix > 0 && this.timeCurrent >= timeUnix) return true
      return false
    }
  }
}
