import { CF_REQUEST_TRANSFER_STATUS } from '@/utils/key-mapping'

export default {
  methods: {
    formatRequestTransferStatus(status) {
      let statusEl

      switch (status) {
        case CF_REQUEST_TRANSFER_STATUS.invalid:
          statusEl =
            '<span class="text-xs">Request</span><br><p class="text-bold text-uppercase text-error">Invalid</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.requestInitial:
          statusEl = '<span class="text-xs">Request</span><br><p class="text-bold text-uppercase">Initial</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.requestPending:
          statusEl =
            '<span class="text-xs text-warning">Request</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.requestSuccess:
          statusEl = '<p class="text-bold text-uppercase text-success">Need Review</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.requestFailed:
          statusEl =
            '<span class="text-xs text-error">Request</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.rejected:
          statusEl = '<p class="text-bold text-error text-uppercase">Rejected</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.approveInitial:
          statusEl = '<span class="text-xs">Approve</span><br><p class="text-bold text-uppercase">Initial</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.approvePending:
          statusEl =
            '<span class="text-xs text-warning">Approve</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.approveSuccess:
          statusEl = '<p class="text-bold text-uppercase text-success">Approved</p>'
          break
        case CF_REQUEST_TRANSFER_STATUS.approveFailed:
          statusEl =
            '<span class="text-xs text-error">Approve</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
      }

      return statusEl
    }
  }
}
