<template>
  <div>
    <div class="d-flex">
      <ul class="tab">
        <li class="tile tile--tab tab__item active">
          <router-link :to="{ name: 'Withdraw' }" tag="p" exact class="tile__content text-uppercase"
            >Withdraw Requests</router-link
          >
        </li>
        <li
          v-if="currentUser && $auth('Page', currentUser, $accessRule.CrowdfundingAdminView).canView()"
          class="tile tile--tab tab__item"
        >
          <router-link :to="{ name: 'WithdrawAdmins' }" tag="p" exact class="tile__content text-uppercase"
            >Crowdfunding Admins</router-link
          >
        </li>
      </ul>

      <router-link tag="button" class="btn btn--primary" :to="{ name: 'WithdrawNew' }">Create a Request</router-link>
    </div>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <selection-box
        v-model="params.status"
        :options="withdrawStatusOptions"
        :on-check="onGetRequestTransfers"
        selected-box-class="is-lg"
      />

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :on-check="onGetRequestTransfers"
        :is-align-right="true"
      />
    </div>

    <div
      v-if="requestTransfers && requestTransfers.data && requestTransfers.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Wallet Address<br />Receive Withdraw</th>
            <th>Email</th>
            <th>Name</th>
            <th>Withdraw<br />Req. Amount</th>
            <th>Request Date</th>
            <th>Request Note</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="request in requestTransfers.data"
            :key="request.id"
            :to="{ name: 'WithdrawDetail', params: { id: request.id } }"
            tag="tr"
            class="link--clone"
          >
            <td class="wr-25">
              <p class="text-ellipsis">{{ request.adminRequest.address }}</p>
            </td>
            <td>{{ request.adminRequest.email }}</td>
            <td>{{ request.adminRequest.fullName }}</td>
            <td>{{ request.amount | number_with_commas }} {{ request.token ? request.token.symbol : '' }}</td>
            <td v-html="getMomentLTSFormatted(request.requestAt)" />
            <td>
              <p class="text-ellipsis">{{ request.requestNote ? request.requestNote : '_' }}</p>
            </td>
            <td v-if="expiredStatus(request)" class="mw-136">
              <p class="text-bold text-gray text-uppercase">Expired</p>
            </td>
            <td v-else v-html="formatRequestTransferStatus(request.status)" class="mw-136" />
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ requestTransfers.total | format_request_count_text }} in total</p>

        <pagination
          v-if="isNextPage(requestTransfers.total)"
          v-model="page"
          :page-count="getPageCount(requestTransfers.totalPages, requestTransfers.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <p class="mt-24" v-else>No request available</p>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { WITHDRAW_STATUS_OPTIONS, PAGER_OPTIONS } from '@/utils/data-sets'
import { CF_REQUEST_TRANSFER_STATUS } from '@/utils/key-mapping'
import momentltsFormat from '@/mixins/momentlts-format'
import requestTransferStatus from '@/mixins/request-transfer-status'
import timeCompare from '@/mixins/time-compare'
import pagination from '@/mixins/pagination'
import handleAssets from '@/mixins/handle-assets'
import toggleLoading from '@/mixins/toggle-loading'
const SelectionBox = () => import('@/components/SelectionBox')
const Pagination = () => import('@/components/Pagination')

export default {
  data() {
    return {
      params: {
        status: 0,
        perPage: 20
      },
      page: 1,
      withdrawStatusOptions: WITHDRAW_STATUS_OPTIONS,
      pagerOptions: PAGER_OPTIONS,
      requestStatus: CF_REQUEST_TRANSFER_STATUS
    }
  },

  mixins: [momentltsFormat, requestTransferStatus, timeCompare, pagination, handleAssets, toggleLoading],

  components: {
    SelectionBox,
    Pagination
  },

  computed: {
    ...mapState('requestTransfer', ['requestTransfers', 'assets'])
  },

  methods: {
    ...mapState('requestTransfer', ['CLEAR_REQUEST_TRANSFERS']),

    ...mapActions('requestTransfer', ['getRequestTransfers']),

    getRequestTransferParams() {
      return {
        page: this.page,
        limit: this.params.perPage,
        status: this.params.status
      }
    },

    onGetRequestTransfers() {
      this.toggleLoading()
      this.page = 1
      this.getRequestTransfers(this.getRequestTransferParams()).finally(() => {
        this.toggleLoading()
      })
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getRequestTransfers(this.getRequestTransferParams()).finally(() => {
        this.toggleLoading()
      })
    },

    expiredStatus(request) {
      return request.status < this.requestStatus.rejected && this.isExpiredTime(request.expire_at_milliseconds)
    }
  },

  created() {
    this['CLEAR_REQUEST_TRANSFERS']()
    this.getRequestTransfers(this.getRequestTransferParams()).finally(() => {
      this.toggleLoading()
    })
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'WithdrawDetail') {
      this.storeAssets('requestTransfer')
      next()
    } else {
      this.clearAssets('requestTransfer')
      next()
    }
  }
}
</script>
